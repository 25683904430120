<template>
  <v-select
      v-model="selected"
      :class="classObject"
      :options="list"
      :reduce="result => mode === 'object' ? result : result.id"
      label="label"
      @input="setChange"
      @search="debounceSearch"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input
          :class="classObject"
          class="vs__search"
          v-bind="attributes"
          v-on="events"
      />
    </template>
  </v-select>
</template>
<script>
import _service from "../../services/usersService";
import {isEmpty, debounce} from 'lodash';
import VSelect from 'vue-select';

export default {
  components: {VSelect},
  props: ["value", "mode", "validator"],
  data() {
    return {
      selected: this.value,
      list: [],
    };
  },
  created() {
    this.debounceSearch = debounce(this.search, 300);
    this.setValue();
  },
  watch: {
    value: async function (value) {

      if (this.mode === 'object') {
        if (isEmpty(value) || value === undefined || value === null) {
          this.selected = this.mode === 'object' ? {} : '';
          return;
        }
      } else {
        if (value === '' || value === undefined || value === null) {
          this.selected = this.mode === 'object' ? {} : '';
          return;
        }
      }

      // check if the value is an object or a string

      let id = typeof value === 'object' ? value.id : value;

      if (isEmpty(this.list.find(x => Number(x.id) === Number(id)))) {

        let response = await _service.show(id);

        this.list.push({
          label: `${response.data.name}`,
          id: response.data.id
        });
        // set the selected value depending on the mode
        this.selected = this.mode === 'object' ? this.list.find(x => x.id === id) : id;
        this.$emit('input', this.selected);
      }

      if(!isEmpty(this.selected)){
        this.selected = this.mode === 'object' ? this.list.find(x => x.id === id) : id;
        this.$emit('input', this.selected);
      }
    },
  },
  methods: {
    async search(search, loading) {

      if (search === '') return;

      loading(true);

      let response = await _service.index(search);
      this.list = response.data.map(x => {
        return {
          ...x,
          label: `${x.name}`
        }
      });

      loading(false);
    },
    setChange(value) {
      this.$emit('input', value);
    },
    async setValue(){

      if (this.mode === 'object') {
        if (isEmpty(this.value) || this.value === undefined || this.value === null) {
          this.selected = this.mode === 'object' ? {} : '';
          return;
        }
      } else {
        if (this.value === '' || this.value === undefined || this.value === null) {
          this.selected = this.mode === 'object' ? {} : '';
          return;
        }
      }

      let id = this.mode === 'object' ? this.value.id : this.value;

      if (isEmpty(this.list.find(x => x.id === id))) {

        let response = await _service.show(id);

        this.list.push({
          label: `${response.data.name}`,
          id: response.data.id
        });

        this.selected = this.list.find(x => x.id === id);
      }

      //this.$emit('input', this.selected);
    }
  },
  computed: {
    classObject: function () {

      if (this.validator === undefined || this.validator === null) {
        return '';
      }

      return this.validator.$error ? 'v-select-error is-invalid' : 'is-valid';
    }
  }
}
</script>



