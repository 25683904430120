import Service from "./Service";
const baseurl = '/api/notas-cargo';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByIdOrden(id_orden) {
        return Service.get(`${baseurl}/show-by-IdOrden/${id_orden}`);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`;
    },
}