<template>
    <main>
        <page-header-compact>
            Listado de historias clinicas
        </page-header-compact>

        <div class="container-fluid px-4 mt-2">
            <div class="card card-icon mb-2">
                <div class="row no-gutters">
                    <div class="col-auto card-icon-aside bg-light">
                        <i class="fas fa-notes-medical"></i>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                    <label class="font-weight-bolder">Profesional</label>
                                    <v-select-user v-model="pagination.params.id_user"></v-select-user>
                                </div>

                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                    <label class="font-weight-bolder">Sede</label>
                                    <VSelectSede v-model="pagination.params.id_sede"></VSelectSede>
                                </div>

                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                    <label class="font-weight-bolder">Fechas </label>
                                    <div>
                                        <date-picker class="w-100" required v-model="range_fechas" range
                                            valueType="format"></date-picker>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                    <label class="font-weight-bolder">Usuario</label>
                                    <input class="form-control form-control-sm" v-model="pagination.params.usuario" />
                                </div>
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label font-weight-bolder">Tipo de Atención</label>
                                        <select v-model="pagination.params.id_tipo_historia"
                                            class="form-control form-control-sm">
                                            <option :value="item.id" v-for="item in tipos_historias" :key="item.id">
                                                {{ item.nombre }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4 col-md-4 col-sm-12">
                                    <label class="font-weight-bolder">No. Historia</label>
                                    <input class="form-control form-control-sm" type="number"
                                        v-model="pagination.params.id_historia" />
                                </div>
                                <div class="mt-3 py-3">
                                    <button class=" btn btn-secondary btn-sm mx-2" @click="getHistoriaClinica();"><i
                                            class="fa fa-search fa-fw"></i>Buscar</button>
                                    <button class="btn btn-warning btn-sm" @click="resetFilters()">
                                        <i class="fa fa-broom fa-fw"></i> Limpiar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div v-show="!pagination.data.length" class="alert alert-info">
                        Sin resultados...
                    </div>
                    <div>
                        <data-grid :data="pagination" @changePage="changePage($event)"
                            @perPageChange="perPageChange($event)" :searchBox="false">
                            <template #grid>
                                <div class="card card-icon mb-3 shadow-none border-left-lg"
                                    v-for="(item, i) in pagination.data" :key="`d_${i}`"
                                    :class="{ 'border-gray-300': item.finalizada === 'S', 'border-warning': item.finalizada === 'N' }">
                                   
                                    <div class="row ">
                                        <div class="col-lg-4 bg-light">
                                            <div class="card-body text-center">

                                                <h3 class="font-weight-bolder">
                                                    H.C {{ item.id }}
                                                </h3>

                                                <span
                                                    class="font-weight-bolder badge badge-secondary-soft badge-marketing">
                                                    {{ item.tipo_historia.nombre }}
                                                </span>
                                                <div class="mt-2">
                                                    <span class="font-weight-bolder badge badge-white badge-marketing">
                                                        <i class="fa fa-calendar fa-fw"></i>{{ item.created_at |
                                        formatDateTime
                                                        }}
                                                    </span>
                                                    <span class="font-weight-bolder badge">
                                                        <i class="fa fa-user-md fa-fw"></i>{{ item.user.name }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <button class="btn btn-success btn-xs"
                                                        v-if="_can('open.hc') && (item.finalizada === 'S' || item.ingreso === 'finalizado')"
                                                        @click="openModalHC(item)" title="Abrir Historia">
                                                        <i class="fa fa-folder-open fa-fw"></i> Abrir Historia
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card-body py-3">
                                                <div class="d-flex">
                                                    <span class="font-weight-bolder"><i class="fa fa-user fa-fw"></i>
                                                        ({{ item.usuario.tipo_documento.codigo }} {{
                                        item.usuario.documento }})
                                                        {{ item.usuario.nombre_1 }} {{ item.usuario.nombre_2 }}
                                                        {{ item.usuario.apellido_1 }} {{ item.usuario.apellido_2 }}
                                                    </span>

                                                </div>
                                                <span class="badge badge-info-soft badge-marketing mt-2">{{
                                        getYears(item.usuario.fecha_nacimiento,
                                            item.fecha) }}</span>

                                                <div class="mt-2">
                                                    <small class="font-italic" v-if="item.eps"><i
                                                            class="fas fa-hospital-alt fa-fw"></i>
                                                        {{ item.eps.DESEPS }}</small>
                                                    <small v-else>Sin Eps</small>
                                                </div>
                                                <div class="btn-group shadow-none mt-2" role="group">
                                                    <button @click="imprimir(item.id);"
                                                        class="btn btn-outline-primary  btn-xs"
                                                        title="Imprimir Historia">
                                                        <i class="fa fa-print fa-fw"></i> H.C Completa
                                                    </button>
                                                    <button @click="resumen(item.id);"
                                                        class="btn btn-outline-success  btn-xs" title="Resumen">
                                                        <div v-if="item.tipo_historia.codigo!=='URG'"><i class="fa fa-print fa-fw "></i> Resumen</div>
                                                        <div v-else><i class="fa fa-print fa-fw "></i> Epicrisis</div>
                                                    </button>
                                                    <button v-if="item.formulas.length" @click="formulaModal(item.id);"
                                                        class="btn btn-outline-info  btn-xs" title="Fórmula médica">
                                                        <i class="fa fa-print fa-fw "></i> Fórmulas
                                                    </button>
                                                    <button v-if="item.ordenes.length"
                                                        @click="ordenModal(item.id, 'procedimiento');"
                                                        class="btn btn-outline-secondary  btn-xs" title="Orden médica">
                                                        <i class="fa fa-print fa-fw "></i> Orden Procedimiento
                                                    </button>
                                                    <button
                                                        v-if="item.ordenes.length && item.tipo_historia.codigo === 'URG'"
                                                        @click="ordenModal(item.id, 'medicamento');"
                                                        class="btn btn-outline-secondary  btn-xs" title="Orden médica">
                                                        <i class="fa fa-print fa-fw "></i> Orden Medicamento
                                                    </button>
                                                    <button v-if="item.remisiones.length" type="button"
                                                        class="btn btn-outline-dark  btn-xs" title="Remisiones"
                                                        @click="abrirModal(item.id)">
                                                        <i class="fa fa-print fa-fw "></i> Remisiones
                                                    </button>
                                                    <button v-if="item.evoluciones.length" type="button"
                                                        class="btn btn-outline-warning   btn-xs" title="Evoluciones"
                                                        @click="EvolucionModal(item.id)">
                                                        <i class="fa fa-print fa-fw "></i> Evoluciones
                                                    </button>
                                                    <button v-if="item.incapacidades.length" type="button"
                                                        class="btn btn-outline-danger   btn-xs" title="Incapacidades"
                                                        @click="IncapacidadModal(item.id)">
                                                        <i class="fa fa-print fa-fw "></i> Incapacidades
                                                    </button>
                                                    <button  type="button"
                                                        class="btn btn-outline-secondary   btn-xs" title="Notas de Enfermeria"
                                                        @click="openModalNotasEnfermeria(item.id_usuario)">
                                                        <i class="fa fa-print fa-fw "></i> Notas Enfermería
                                                    </button>
                                                </div>
                                                <div class="d-flex justify-content-end mt-3">
                                                    <small class="font-weight-bolder" v-if="item.sede"><i
                                                            class="fas fa-map-marker-alt fa-fw"></i> {{
                                                            item.sede.NOMBRE_SEDE }}
                                                    </small>
                                                </div>
                                                <div class="justify-content-end">

                                                    <div class="d-flex justify-content-end">
                                                        <div class="mx-2 mt-2">
                                                            <span
                                                                class="badge badge-success-soft badge-marketing font-weight-bolder"
                                                                v-if="item.finalizada == 'S'">Finalizada</span>
                                                            <span
                                                                class="badge badge-warning-soft badge-marketing font-weight-bolder"
                                                                v-else>Sin Finalizar</span>
                                                        </div>
                                                        <div class="btn-group shadow-none mt-2" role="group">

                                                            <button v-if="_can('change.state.hc')"
                                                                class="btn btn-secondary btn-xs" type="button"
                                                                @click="cambiar(item.id)">
                                                                <i class="fas fa-edit fa-fw"></i> Cambiar Estado
                                                            </button>
                                                            <button v-if="_can('change.eps.hc')"
                                                                class="btn btn-warning btn-xs" type="button"
                                                                @click="openModalChangeEps(item)">
                                                                <i class="fas fa-edit fa-fw"></i> Cambiar Eps
                                                            </button>
                                                            <button v-if="_can('anular.hc')"
                                                                class="btn btn-danger btn-xs" type="button"
                                                                @click="openModalAnularHC(item)">
                                                                <i class="fas fa-ban fa-fw"></i> Anular historia
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </data-grid>
                        <modal-remisiones ref="modalRemisiones"></modal-remisiones>
                        <modal-ordenes ref="modalOrdenes"></modal-ordenes>
                        <modal-formulas ref="modalFormulas"></modal-formulas>
                        <modal-evoluciones ref="modalEvoluciones"></modal-evoluciones>
                        <modal-incapacidades ref="modalIncapacidades"></modal-incapacidades>
                        <modalOpenHC ref="modalHC"></modalOpenHC>
                        <modalChangeEps ref="modalEps" v-on:cambio-eps="getHistoriaClinica()"></modalChangeEps>
                        <modalAnularHc ref="modalAHc" v-on:anular-hc="getHistoriaClinica()"></modalAnularHc>
                        <modalNotasEnfermeria ref="modalNotasEnfermeria"></modalNotasEnfermeria>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import Toast from '../../../components/common/utilities/toast';
import vSelectUser from "../../../components/common/vSelectUser";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VSelectSede from '../../../components/common/VSelectSede';
import historiaClinicaService from '../../../services/historiaClinicaService';
import modalRemisiones from "../base/baseHistorialHistoria/modalRemisiones";
import modalOrdenes from "../base/baseHistorialHistoria/modalOrdenes";
import modalFormulas from "../base/baseHistorialHistoria/modalFormulas";
import modalEvoluciones from "../base/baseHistorialHistoria/modalEvoluciones";
import modalIncapacidades from "../base/baseHistorialHistoria/modalIncapacidades";
import modalOpenHC from "../base/baseHistorialHistoria/abrirHistoria/modalOpenHC";
import modalChangeEps from "./changeEps";
import modalAnularHc from "./anularHc";
import tipoHistoriaService from "../../../services/tipoHistoriaService";
import dayjs from 'dayjs';
import modalNotasEnfermeria from "./modalNotasEnfermeria";

export default {
    components: {
        PageHeaderCompact, DataGrid,
        vSelectUser, VSelectSede, DatePicker, modalRemisiones, modalOrdenes, modalFormulas, modalEvoluciones, modalIncapacidades,
        modalOpenHC, modalChangeEps,modalAnularHc,modalNotasEnfermeria
    },
    data() {
        return {
            range_fechas: '',
            pagination: {
                data: [],
                params: {
                    fecha: '',
                    id_user: '',
                    usuario: "",
                    id_tipo_historia: "",
                    id_sede: "",
                    id_historia: ""
                },
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
            },
            tipos_historias: []
        }
    },

    methods: {
        async getHistoriaClinica() {
            try {
                this.LoaderSpinnerShow();
                this.pagination.params.start_date = this.range_fechas[0];
                this.pagination.params.end_date = this.range_fechas[1];

                const response = (await historiaClinicaService.showByHC(this.pagination.search, this.pagination))?.data;

                this.pagination.data = response.data;
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }

        },
        imprimir(id) {
            window.open(historiaClinicaService.print(id));
        },
        resumen(id) {
            window.open(historiaClinicaService.resumen(id));
        },
        abrirModal(id_historia) {
            this.$refs.modalRemisiones.abrir(id_historia);
        },
        formulaModal(id_historia) {
            this.$refs.modalFormulas.abrir(id_historia);
        },
        ordenModal(id_historia, type) {
            this.$refs.modalOrdenes.abrir(id_historia, type);
        },
        EvolucionModal(id_historia) {
            this.$refs.modalEvoluciones.abrir(id_historia);
        },
        IncapacidadModal(id_historia) {
            this.$refs.modalIncapacidades.abrir(id_historia);
        },
        openModalNotasEnfermeria(idUsuario) {
            this.$refs.modalNotasEnfermeria.openModal(idUsuario);
        },
        async cambiar(id) {
            try {
                this.LoaderSpinnerShow();
                await historiaClinicaService.cambiarEstado(id);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Estado cambiado exitosamente'
                });
                this.getHistoriaClinica();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getHistoriaClinica();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getHistoriaClinica();
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getHistoriaClinica();
        },
        openModalHC(historia) {
            this.$refs.modalHC.openModal(historia);
        },
        openModalChangeEps(historia) {
            this.$refs.modalEps.openModal(historia);
        },
        openModalAnularHC(historia) {
            this.$refs.modalAHc.openModal(historia);
        },
        resetFilters() {
            this.pagination.params.id_user = "";
            this.pagination.params.start_date = "";
            this.pagination.params.end_date = "";
            this.pagination.params.id_sede = "";
            this.pagination.params.id_tipo_historia = "";
            this.pagination.params.id_historia = "";
            this.range_fechas = {};
            this.pagination.params.usuario = "";
            this.getHistoriaClinica();
        },
        async getTiposHistorias() {
            const response = await tipoHistoriaService.index();
            this.tipos_historias = response.data;
        },
        getYears(fechaNacimiento, fechaAtencion) {
            const fechaNacimientoDayjs = dayjs(fechaNacimiento);
            const diaAtencionDayjs = dayjs(fechaAtencion);

            const años = diaAtencionDayjs.diff(fechaNacimientoDayjs, 'year');

            // Ajustar la fecha de nacimiento al año de la fecha de atención
            const fechaNacimientoConAños = fechaNacimientoDayjs.add(años, 'year');

            // Calcular la diferencia en meses
            const meses = diaAtencionDayjs.diff(fechaNacimientoConAños, 'month');

            // Ajustar la fecha de nacimiento al mes de la fecha de atención
            const fechaNacimientoConMeses = fechaNacimientoConAños.add(meses, 'month');

            // Calcular la diferencia en días
            const días = diaAtencionDayjs.diff(fechaNacimientoConMeses, 'day');

            return `${años} años ${meses} meses ${días} dias`;
        }
    },
    async created() {
        await this.getTiposHistorias();
    }

}
</script>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
