<template>
    <div class="modal fade" id="modalAnularHc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-sync fa-fw"></i> Anular Historia Clinica No. {{ historia_clinica.id }}
                    </h5>
                    <div class="d-flex justify-content-end mx-2">
                        <span class="badge badge-secondary-soft badge-marketing" v-if="historia_clinica.tipo_historia"> {{
                            historia_clinica.tipo_historia.nombre }}</span>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <div class="justify-content-between mx-2 font-weight-bolder" v-if="historia_clinica.user">
                            <i class="fa fa-user fa-fw"></i> {{ historia_clinica.user.name }}
                        </div>
                        <div class="justify-content-end mx-2 font-weight-bolder">
                            <i class="fa fa-calendar fa-fw"></i> {{ historia_clinica.fecha | formatDate }}
                        </div>
                    </div>
                    <div class="mt-2">
                        <small class="font-italic" v-if="historia_clinica.eps"><i class="fas fa-hospital-alt fa-fw"></i>
                            {{ historia_clinica.eps.DESEPS }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-6 col-form-label font-weight-bolder"><i class="fa fa-comment fa-fw"></i>
                            Motivo </label>
                        <div class="col-sm-12">
                            <textarea cols="30" rows="5" class="form-control" v-model="motivo"
                                :class="{ 'is-invalid': $v.motivo.$error && $v.motivo.$dirty }"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success btn-sm" @click="guardar();">Anular <i
                            class="fa fa-ban fa-fw"></i></button>
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar <i
                            class="fa fa-times-circle fa-fw"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import historiaClinicaService from "../../../services/historiaClinicaService";
import $ from 'jquery';
import Toast from '../../../components/common/utilities/toast';
import { required } from "vuelidate/lib/validators";

export default {
    data() {
        return {
            motivo: '',
            historia_clinica: {}
        }
    },
    validations() {
        return {
            motivo:{ required}
        }
    },
    methods: {
        async guardar() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) return;

                this.LoaderSpinnerShow();
                await historiaClinicaService.anularHc({
                    motivo:this.motivo,
                    id_historia:this.historia_clinica.id
                });
                this.LoaderSpinnerHide();
                this.limpiarCampos();

                $(`#modalAnularHc`).modal('hide');

                this.$emit('anular-hc');

                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con éxito'
                });


            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos() {
            this.motivo = '';
        },
        openModal(historia) {
            this.$nextTick(async () => {
                $('#modalAnularHc').modal('show');
                this.historia_clinica = historia;
            });
        },
    },
}
</script>
