<template>
    <div class="modal fade" id="openHC" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-folder-open fa-fw"></i> Abrir Historia Clinica No. {{ historia_clinica.id }}
                    </h5>
                    <div class="d-flex justify-content-end mx-2">
                        <span class="badge badge-secondary-soft badge-marketing" v-if="historia_clinica.tipo_historia"> {{
                            historia_clinica.tipo_historia.nombre }}</span>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <div class="justify-content-between mx-2 font-weight-bolder" v-if="historia_clinica.user">
                            <i class="fa fa-user fa-fw"></i> {{ historia_clinica.user.name }}
                        </div>
                        <div class="justify-content-end mx-2 font-weight-bolder">
                            <i class="fa fa-calendar fa-fw"></i> {{ historia_clinica.fecha | formatDate }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-6 col-form-label font-weight-bolder"><i class="fa fa-comment fa-fw"></i>
                            Motivo </label>
                        <div class="col-sm-12">
                            <textarea cols="30" rows="5" class="form-control" v-model="motivo"
                                :class="{ 'is-invalid': $v.motivo.$error && $v.motivo.$dirty }"></textarea>
                                <span class="text-danger text-sm" v-if="!$v.motivo.minLengthValue">Debe ingresar un minimo de 10 caracteres</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success btn-sm" @click="guardar();">Abrir Historia<i
                            class="fa fa-folder-open fa-fw"></i></button>
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i
                            class="fa fa-times-circle fa-fw"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import historiaClinicaService from "../../../../../services/historiaClinicaService";
import $ from 'jquery';
import Toast from '../../../../../components/common/utilities/toast';
import { required,minLength } from "vuelidate/lib/validators";

export default {
    data() {
        return {
            motivo: '',
            historia_clinica: {}
        }
    },
    validations() {
        return {
            motivo: { required,
            minLengthValue:minLength(10)
         }
        }
    },
    methods: {
        async guardar() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) return;

                let motivos = {
                    id_historia: this.historia_clinica.id,
                    motivo: this.motivo,
                }

                this.LoaderSpinnerShow();
                await historiaClinicaService.openHc(motivos);
                this.LoaderSpinnerHide();
                this.limpiarCampos();

                $(`#openHC`).modal('hide');

                Toast.fire({
                    icon: 'success',
                    title: 'Historia Ha sido abierta con exito'
                });

                this.$router.push({
                    name : this.historia_clinica.tipo_historia.path,
                    params : {
                        id : this.historia_clinica.id
                    }
                });

            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos() {
            this.motivo = '';
        },
        openModal(historia) {
            this.$nextTick(async () => {
                $('#openHC').modal('show');
                this.historia_clinica = historia;
            });
        }

    },
}
</script>
