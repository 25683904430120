<template>
  <main>
    <div id="modalOrdenes" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Listado de Ordenes, Historia No: {{ id_historia }}</h5>
            <button aria-label="Close" class="close" type="button" @click="cerrar();">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="isLoading" class="card mt-2 shadow-none">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false"/>
                  <content-placeholders-text :lines="3"/>
                </content-placeholders>
              </div>
            </div>
            <div v-else-if="!ordenes.length" class="alert alert-warning mt-2">
              No se han cargado ordenes...
            </div>
            <div v-for="item in ordenes" v-else :key="item.id" class="card mt-2 border-gray-500 shadow-none">
              <div class="card-header card-header-actions bg-light d-flex justify-content-between">
                <h5 class="mb-1">Orden No. {{ item.id }}</h5>
                <div class="w-75 d-flex justify-content-between">
                  <span class="text-danger mx-2">{{ item.user.name }}</span>
                  <small>({{ item.created_at | formatDateTime }})</small>
                  <div class="d-flex justify-content-between">
                    <button class="btn btn-outline-info lift shadow-sm btn-sm" @click="imprimir(item.id);">
                      <i class="fa fa-print fa-fw"></i>
                    </button>
                    <!-- Fade In Up Animation -->
                    <div v-if="item.notas_cargo !== null" class="dropdown">
                      <button id="dropdownFadeInUp"
                              aria-expanded="false" aria-haspopup="true" class="btn btn-outline-secondary lift shadow-sm btn-sm dropdown-toggle" data-toggle="dropdown"
                              type="button">
                        <i class="fas fa-first-aid fa-fw"></i>
                      </button>
                      <div class="dropdown-menu animated--fade-in-up" title="Notas de cargo">
                        <a v-for="(nota,index) in item.notas_cargo" :key="index" class="dropdown-item"
                           href="#" @click.prevent="printChargeNote(nota.id)">
                          <i class="far fa-file-alt fa-fw"></i>
                          Nota de Cargo Nro. {{ nota.id }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div v-for="procedimiento in item.items" :key="procedimiento.id"
                       class="col-md-8 col-sm-12 col-lg-12 font-weight-bold">
                    {{ procedimiento.servicio.codigo }}
                    {{ procedimiento.servicio.descripcion }}
                    <div class="small text-muted d-none d-md-block">
                      Observación: {{ procedimiento.observacion }}
                    </div>
                    <hr>
                  </div>
                  <div class="col-md-8 col-sm-12 col-lg-12">
                    Observaciones Generales: <span class="text-primary">{{ item.observaciones }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-light btn-sm" type="button" @click="cerrar();"><i
                class="far fa-times-circle fa-fw"></i> Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>
<script>
import historiaClinicaService from '../../../../services/historiaClinicaService';
import ordenClinicaService from '../../../../services/ordenClinicaService';
import notasCargoService from "@/services/notasCargoService";
import $ from "jquery";

export default {
  data() {
    return {
      id_historia: '',
      ordenes: {},
      isLoading: true,
    }
  },
  methods: {
    async abrir(id, type) {
      this.$nextTick(async () => {
        this.id_historia = id;
        $("#modalOrdenes").modal("show");
        this.isLoading = true;
        const response = await ordenClinicaService.showOrdenByHcAndType({
          id_historia: id,
          type: type
        });
        this.ordenes = response.data;
        this.isLoading = false;
      });
    },
    imprimir(id_orden) {
      window.open(historiaClinicaService.orden(id_orden));
    },
    cerrar() {
      $("#modalOrdenes").modal("hide");
      this.ordenes = {};
    },
    printChargeNote(id) {
      window.open(notasCargoService.print(id), '_blank');
    }
  },
}
</script>
